/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prázdna stránka"}>
        <SiteHeader />

        <Column className="pb--60 pt--80" name={"intro-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span>Page title</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"content"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Heading"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1kjesrv pb--60 pt--60" name={"contact"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/151/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">Got questions?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--20" content={"Get in touch"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box" content={"List of services"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn4" content={"Pricelist"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"722 Finch Street<br>info@vase-stranky.com<br>510-851-40X4"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":449}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized.<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}